import { Slide } from 'react-awesome-reveal';
export default function Contact() {
  return (
    <div id='contact'>
      <Slide triggerOnce fraction={0.5} direction="up">
      <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl' style={{textAlign:"center",fontFamily:"Poppins",margin:"5rem"}}>Let's Build Something Amazing! 🎉</h1>
      </Slide>
      <button className="btn btn-outline" style={{position:"relative",left:"50%",transform:"translateX(-50%)"}}><a href="mailto:drpeiris3@gmail.com">Drop Me a Message!</a></button>
      
      
    </div>
  )
}
