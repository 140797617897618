import Contact from "./ContactSection/Contact"
import { Footer } from "./FooterSection/Footer"


import Hero from "./HeroSection/Hero"
import Projects from "./ProjectsSection/Projects"
import Skills from "./SkillsSection/Skills"








function App() {
  

  return (
    <>
      <Hero />
      <Projects />
      <Skills  />
      <Contact  />
      <Footer />

    </>
  )
}

export default App
