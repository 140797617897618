

import GitHubIcon from '@mui/icons-material/GitHub';

import { Card } from 'flowbite-react';
import { Slide } from 'react-awesome-reveal';

export default function Projects() {


  return (
    <div id='projects'>
      <Slide triggerOnce direction='up' >
      <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl' style={{textAlign:"center",fontFamily:"Poppins"}}>Projects.</h1>
      </Slide>
      

      <div style={{display:"flex",justifyContent:"center",alignContent:"center",flexWrap:"wrap",marginTop:"5rem"}}>
        

      <Card href="#" className="max-w-sm m-4" onClick={()=>{window.location.href='https://github.com/DamianRavinduPeiris/NextTravelBackEnd'}}>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        NextTravel.
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400" style={{fontFamily:"Poppins"}}>
      Spring Boot Based Hotel Booking system which shows the implementation of Mircoservices architecture.
      </p>
      <div style={{display:"flex",justifyContent:"center",alignContent:"space-around",flexWrap:"wrap"}}>
      <div className="badge badge-neutral m-2">Java</div>
      <div className="badge badge-neutral m-2">Spring Boot</div>
      <div className="badge badge-neutral m-2">Mircoservices</div>
      <div className="badge badge-neutral m-2">Spring Security + JWT</div>
      <div className="badge badge-neutral m-2">Google Drive API</div>
      <div className="badge badge-neutral m-2">Stripe API</div>
      </div>
      <a href="https://github.com/DamianRavinduPeiris/NextTravelBackEnd"><GitHubIcon style={{color:"black",fontSize:"2rem",margin:"1rem"}}/></a>
      
    </Card>
    
    <Card href="#" className="max-w-sm m-4" onClick={()=>{window.location.href='https://github.com/DamianRavinduPeiris/Streamz-FrontEnd'}}>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        Streamz.
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400" style={{fontFamily:"Poppins"}}>
      Streamz is a web application built with MERN stack, which lets you watch  movies and TV shows. 
      </p>
      <div style={{display:"flex",justifyContent:"center",alignContent:"space-around",flexWrap:"wrap"}}>
      <div className="badge badge-neutral m-2">MongoDB</div>
      <div className="badge badge-neutral m-2">Express</div>
      <div className="badge badge-neutral m-2">React</div>
      <div className="badge badge-neutral m-2">Express</div>
      
      </div>
      <a href="https://github.com/DamianRavinduPeiris/Streamz-FrontEnd"><GitHubIcon style={{color:"black",fontSize:"2rem",margin:"1rem"}}/></a>
      
    </Card>
    
    <Card href="#" className="max-w-sm m-4" onClick={()=>{window.location.href='https://github.com/DamianRavinduPeiris/YTS-CLI'}}>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
      YTS - CLI.
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400" style={{fontFamily:"Poppins"}}>
      A CLI tool written in Java,which can be used to download movie torrents from yts.mx 
      </p>
      <div style={{display:"flex",justifyContent:"center",alignContent:"space-around",flexWrap:"wrap"}}>
      <div className="badge badge-neutral m-2">Java</div>
      <div className="badge badge-neutral m-2">GSON</div>
      <div className="badge badge-neutral m-2">Maven</div>
      <div className="badge badge-neutral m-2">yts.mx API</div>
      
      
      </div>
      <a href="https://github.com/DamianRavinduPeiris/YTS-CLI"><GitHubIcon style={{color:"black",fontSize:"2rem",margin:"1rem"}}/></a>
      
    </Card>
    <Card href="#" className="max-w-sm m-4" onClick={()=>{window.location.href='https://github.com/DamianRavinduPeiris/Institute-Management-System'}}>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
      APEX.
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400" style={{fontFamily:"Poppins"}}>
      An Institute management system purely written in Java , JavaFX and MYSQL which shows the implementation of layered architecture and Java JDBC API. 
      </p>
      <div style={{display:"flex",justifyContent:"center",alignContent:"space-around",flexWrap:"wrap"}}>
      <div className="badge badge-neutral m-2">Java</div>
      <div className="badge badge-neutral m-2">JavaFX</div>
      <div className="badge badge-neutral m-2">JDBC</div>
      <div className="badge badge-neutral m-2">MYSQL</div>
      <div className="badge badge-neutral m-2">Jasper</div>
      <div className="badge badge-neutral m-2">Layered Architecture</div>
      
      
      </div>
      <a href="https://github.com/DamianRavinduPeiris/Institute-Management-System"><GitHubIcon style={{color:"black",fontSize:"2rem",margin:"1rem"}}/></a>
      
    </Card>
    <Card href="#" className="max-w-sm m-4" onClick={()=>{window.location.href='https://github.com/DamianRavinduPeiris/weathersense'}}>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
      WeatherSense.
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400" style={{fontFamily:"Poppins"}}>
      A simple weather app which shows you the real time weather data in the current location and across the world. 
      </p>
      <div style={{display:"flex",justifyContent:"center",alignContent:"space-around",flexWrap:"wrap"}}>
      <div className="badge badge-neutral m-2">HTML</div>
      <div className="badge badge-neutral m-2">CSS</div>
      <div className="badge badge-neutral m-2">JQuery</div>
      <div className="badge badge-neutral m-2">BootStrap 5</div>
      <div className="badge badge-neutral m-2">weatherapi.com API</div>
      <div className="badge badge-neutral m-2">unsplash.com API</div>

      
      
      </div>
      <a href="https://github.com/DamianRavinduPeiris/weathersense"><GitHubIcon style={{color:"black",fontSize:"2rem",margin:"1rem"}}/></a>
      
    </Card >
    <Card href="#" className="max-w-sm m-4" onClick={()=>{window.location.href='https://github.com/DamianRavinduPeiris/Snaps'}}>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
      Snaps.
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400" style={{fontFamily:"Poppins"}}>
      A simple wallpaper website,built on top of the unsplash.com API. 
      </p>
      <div style={{display:"flex",justifyContent:"center",alignContent:"space-around",flexWrap:"wrap"}}>
      <div className="badge badge-neutral m-2">HTML</div>
      <div className="badge badge-neutral m-2">CSS</div>
      <div className="badge badge-neutral m-2">JQuery</div>
      <div className="badge badge-neutral m-2">BootStrap 5</div>
      <div className="badge badge-neutral m-2">unsplash.com API</div>

      
      
      </div>
      <a href="https://github.com/DamianRavinduPeiris/Snaps"><GitHubIcon style={{color:"black",fontSize:"2rem",margin:"1rem"}}/></a>
      
    </Card>
    <Card href="#" className="max-w-sm m-4" onClick={()=>{window.location.href='https://github.com/DamianRavinduPeiris/ChatGPT-CLI'}}>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
      ChatGPT - CLI.
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400" style={{fontFamily:"Poppins"}}>
      A Java-CLI based ChatGPT client built on top of the OpenAI API. 
      </p>
      <div style={{display:"flex",justifyContent:"center",alignContent:"space-around",flexWrap:"wrap"}}>
      <div className="badge badge-neutral m-2">Java</div>
      <div className="badge badge-neutral m-2">GSON</div>
      <div className="badge badge-neutral m-2">Maven</div>
      <div className="badge badge-neutral m-2">openai.com API</div>
      

      
      
      </div>
      <a href="https://github.com/DamianRavinduPeiris/ChatGPT-CLI"><GitHubIcon style={{color:"black",fontSize:"2rem",margin:"1rem"}}/></a>
      
    </Card>
    <Card href="#" className="max-w-sm m-4" onClick={()=>{window.location.href='https://github.com/DamianRavinduPeiris/OrderCloud-JavaEE'}}>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
      OrderCloud.
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400" style={{fontFamily:"Poppins"}}>
      A POS system built using JavaEE servlets and AJAX. 
      </p>
      <div style={{display:"flex",justifyContent:"center",alignContent:"space-around",flexWrap:"wrap"}}>
      <div className="badge badge-neutral m-2">Java</div>
      <div className="badge badge-neutral m-2">JAVA EE</div>
      <div className="badge badge-neutral m-2">GSON</div>
      <div className="badge badge-neutral m-2">MAVEN</div>
      <div className="badge badge-neutral m-2">AJAX</div>
      

      
      
      </div>
      <a href="https://github.com/DamianRavinduPeiris/OrderCloud-JavaEE"><GitHubIcon style={{color:"black",fontSize:"2rem",margin:"1rem"}}/></a>
      
    </Card>
    <Card href="#" className="max-w-sm m-4" onClick={()=>{window.location.href='https://github.com/DamianRavinduPeiris/OrderCloud-JavaEE'}}>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
      Music Findr.
      </h5>
      <p className="font-normal text-gray-700 dark:text-gray-400" style={{fontFamily:"Poppins"}}>
      A simple web application which allows you to search and view song details . 
      </p>
      <div style={{display:"flex",justifyContent:"center",alignContent:"space-around",flexWrap:"wrap"}}>
      <div className="badge badge-neutral m-2">HTML</div>
      <div className="badge badge-neutral m-2">CSS</div>
      <div className="badge badge-neutral m-2">JQuery</div>
      <div className="badge badge-neutral m-2">genius.com API</div>
      
      

      
      
      </div>
      <a href="https://github.com/DamianRavinduPeiris/OrderCloud-JavaEE"><GitHubIcon style={{color:"black",fontSize:"2rem",margin:"1rem"}}/></a>
      
    </Card>

    
          
      </div>
      

    </div>
  )
}