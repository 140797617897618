
import { Card } from 'flowbite-react';
import {Slide } from 'react-awesome-reveal';

export default function Skills() {
  return (
    <div id='skills'>
      <Slide triggerOnce direction='up'>
         <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl' style={{textAlign:"center",fontFamily:"Poppins",margin:"5rem"}}>Skills.</h1>
          </Slide>

         <div style={{display:"flex",justifyContent:"center",alignContent:"center",flexWrap:"wrap",marginTop:"5rem"}}>


         <Card href="#" className="max-w-sm m-4" style={{borderRadius:"40px"}}>
    

      <div style={{display:"flex",justifyContent:"center",alignContent:"space-around",flexWrap:"wrap"}}>
      <div className="badge badge-ghost m-2">Java</div>
      <div className="badge badge-ghost m-2">Spring and Spring Boot</div>    
      <div className="badge badge-ghost m-2">JavaFX</div>
      <div className="badge badge-ghost m-2">Java Swing</div>    
        <div className="badge badge-ghost m-2">Hibernate</div>
        <div className="badge badge-ghost m-2">MYSQL</div>
      <div className="badge badge-ghost m-2">MongoDB</div>
      <div className="badge badge-ghost m-2">Express</div>
      <div className="badge badge-ghost m-2">React</div>
      <div className="badge badge-ghost m-2">Node</div>
      <div className="badge badge-ghost m-2">Git and Github</div>
        <div className="badge badge-ghost m-2">HTML</div>
        <div className="badge badge-ghost m-2">CSS</div>
        <div className="badge badge-ghost m-2">Bootstrap</div>
        <div className="badge badge-ghost m-2">Javascript and Jquery</div>
        <div className="badge badge-ghost m-2">Typescript</div>
        <div className="badge badge-ghost m-2">React Native</div>
        

      
      </div>
      
      
    </Card>
    

         </div>
         
      
    </div>
  )
}
